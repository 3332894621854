.footer {
  position: relative;
}

.footer__container {
  padding: 64px;
  width: Calc(100% - 128px);
  max-width: 1362.72px;
  margin: auto;
}

.footer__content {
  display: flex;
  justify-content: space-between;
}

.footer__logo {
  height: 6vw;
  max-height: 38px;
  margin-bottom: 24px;
}

.billme-info {
  width: 100%;
  max-width: 604px;
  padding-right: 24px;
}

.billme-info p {
  font-size: 16px;
  line-height: 25px;
  vertical-align: middle;
  text-indent: 30px;
}

.contact-billme {
  width: 100%;
  max-width: 351px;
}

.contact-billme span {
  font-weight: 600;
}

.contact-billme span b {
  margin-left: 56px;
  line-height: 24px;
}

.menu {
  width: 100%;
  max-width: 226px;
}

.menu ul li {
  list-style: disc;
  list-style-position: inside;
  color: #00ace1;
  text-decoration: none;
  padding-bottom: 32px;
}

.menu a {
  text-decoration: none;
  color: #00ace1;
}

@media (max-width: 1024px) {
  .footer__container {
    padding: 24px;
    width: Calc(100% - 48px);
  }
}

@media (max-width: 768px) {
  .menu{
    display: none;
  }
}

@media (max-width: 520px) {
  .footer__content {
    flex-direction: column;
  }

  .contact-billme {
    margin-top: 32px;
  }
}
