.selector {
  position: relative;
}

.selector__display {
  border: 1px solid #00ace1;
  border-radius: 8px;
  padding: 13px 12px;
  width: Calc(100% - 26px);
  min-width: 108px;
}

.selector__options {
  display: none;
  position: absolute;
  z-index: 2;
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}

.selector__options.show {
  display: block;
}

.selector__option {
  padding: 13px 12px;
}

.selector__indicator {
  position: absolute;
  right: 13px;
  bottom: 15px
}