.hero {
  position: relative;
  background: linear-gradient(360deg, #00ACE1 0.03%, #0591D9 34.37%, #115CC8 100%), #00ACE1;
}

.hero__header {
  display: flex;
  justify-content: space-between;
  height: 46px;
  margin-bottom: 64px;
  align-items: center;
}

.hero__container {
  padding: 64px;
  width: Calc(100% - 128px);
  max-width: 1362.72px;
  margin: auto;
}

.billme-logo {
  height: 6vw;
  max-height: 38px;
}

.hero-link {
  color: #ffffff;
  text-decoration: none;
  padding: 16px;
  font-weight: 500;
}

.hero-link--button {
  color: #00ace1;
  text-decoration: none;
  padding: 11px 32px;
  margin-left: 16px;
  background-color: #ffffff;
  border-radius: 8px;
  font-weight: 500;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14),
              0px 4px 5px rgba(0, 0, 0, 0.12),
              0px 1px 10px rgba(0, 0, 0, 0.2);
}

.line-logo {
  max-width: 67px;
  vertical-align: middle;
}

.hero__content {
  font-size: 2.4rem;
}

.hero__title {
  font-size: 3em;
  color: #ffffff;
  line-height: 1.2em;
  font-weight: 500;
}

.hero__description {
  color: #ffffff;
  font-size: 1em;
  font-weight: 300;
  line-height: 1.7em;
  margin: 40px 0 64px 0;
}

.in-content {
  margin: 0;
}

.hero__background-image {
  width: 100%;
  height: 200px;
  position: relative;
  pointer-events: none;;
}

.back-wave {
  width: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
}

.front-wave {
  width: 65%;
  position: absolute;
  bottom: 0;
  right: 0;
}

.dashboard {
  width: 35%;
  position: absolute;
  bottom: 60px;
  right: 140px;
  box-shadow: 0px 16px 30px rgba(0, 0, 0, 0.25);
  border-radius: 3%;
}

.bill {
  width: 20%;
  position: absolute;
  bottom: 60px;
  right: 64px;
  box-shadow: 0px 16px 30px rgba(0, 0, 0, 0.25);
  border-radius: 3%;
}

@media (min-width: 1921px) {
  .hero__background-image, .in-content {
    display: none;
  }

  .hero__title, .hero__description {
    text-align: center;
  }
}

@media (max-width: 1024px) {
  .hero__container {
    padding: 24px;
    width: Calc(100% - 48px);
  }
}

@media (max-width: 880px) {
  .hero__menu, .in-content {
    display: none;
  }

  .in-content {
    display: none;
  }
}

@media (max-width: 768px) {
  .hero__header {
    justify-content: center;
    margin-bottom: 40px;
  }

  .hero__title {
    font-size: 2em;
    text-align: center;
  }

  .hero__description {
    font-size: 0.75em;
    text-align: center;
    margin: 36px 0;
  }

  .line-logo {
    max-width: 60px;
  }

  .hero__background-image {
    height: 370px;
  }

  .back-wave {
    width: 150%;
  }

  .front-wave {
    width: 100%;
  }

  .dashboard {
    width: 340px;
    bottom: 40px;
    right: 250px;
  }

  .bill {
    width: 185px;
    bottom: 40px;
    right: 170px;
  }
}

@media (max-width: 767px) {
  .hero__background-image {
    display: none;
  }
}

@media (max-width: 520px) {
  .hero__header {
    margin-bottom: 24px;
  }

  .hero__title {
    font-size: 1.5em;
    text-align: center;
  }

  .hero__description {
    font-size: 0.65em;
    text-align: center;
    margin: 28px 0;
  }

  .line-logo {
    max-width: 48px;
  }
}

@media (max-width: 410px) {
  .hero__header {
    margin-bottom: 16px;
  }

  .hero__title {
    font-size: 1em;
    text-align: center;
  }

  .hero__description {
    font-size: 0.53em;
    text-align: center;
    margin: 24px 0;
  }

  .line-logo {
    max-width: 32px;
  }
}
