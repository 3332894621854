.problem {
  position: relative;
  background-color: #f7f8ff;
}

.problem__container {
  padding: 64px;
  width: Calc(100% - 128px);
  max-width: 1362.72px;
  margin: auto;
}

.problem__content {
  display: flex;
  justify-content: space-between;
}

.problem__content li {
  list-style: disc;
  font-size: 1.6rem;
  line-height: 2.5rem;
  vertical-align: middle;
}

.financial, .management, .customer {
  padding: 0 24px;
}

.financial img, .management img, .customer img {
  width: 100%;
  height: 255px;
}

.problem__title {
  font-size: 48px;
  line-height: 76px;
  vertical-align: middle;
  margin-bottom: 16px;
  text-align: center;
}

.special-title {
  font-size: 32px;
  line-height: 51px;
  vertical-align: middle;
  margin: 8px 0 16px 0;
  text-align: center;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background: linear-gradient(358.66deg, #00ACE1 35.4%, #0591D9 65.62%, #115CC8 99.44%),
              linear-gradient(0deg, #00ACE1, #00ACE1);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -o-background-clip: text;
  background-clip: text;
}

@media (max-width: 1024px) {
  .problem__container {
    padding: 24px;
    width: Calc(100% - 48px);
  }

  .problem__title {
    font-size: 32px;
    line-height: 51px;
  }

  .special-title {
    font-size: 24px;
    line-height: 40px;
  }
}

@media (max-width: 768px) {
  .problem__content {
    flex-direction: column;
    align-items: center;
  }

  .problem__title {
    font-size: 32px;
    line-height: 51px;
  }

  .special-title {
    font-size: 24px;
    line-height: 40px;
  }
}
