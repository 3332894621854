.pricing {
  position: relative;
}

.pricing__container {
  padding: 64px;
  width: Calc(100% - 128px);
  max-width: 1362.72px;
  margin: auto;
}

.pricing__title {
  font-size: 48px;
  line-height: 76px;
  vertical-align: middle;
  text-align: center;
  margin-bottom: 24px;
}

.pricing__content {
  display: flex;
  justify-content: space-around;
}

.pricing__card {
  width: 246px;
  height: 600px;
  padding: 24px 21px;
  box-shadow: 0px 8px 24px #C2C5D8;
  background-color: #ffffff;
  border-radius: 24px;
  position: relative;
}

.card__tag {
  border-radius: 38px;
  padding: 6px 24px;
  width: 120px;
  text-align: center;
  background-color: #d8d8d8;
  color: #404040;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  vertical-align: middle;
}

.marketing-style {
  color: #ffffff;
  background: linear-gradient(359.19deg, #00ACE1 15.71%, #0591D9 53.42%, #115CC8 125.5%), #00ACE1;
}

.ceo-style {
  color: #ffffff;
  background: linear-gradient(360deg, #8DDAD5 0%, #00CDAC 100%);
}

.card__title {
  font-size: 36px;
  line-height: 63px;
  vertical-align: middle;
}

.card__title span {
  font-size: 18px;
  line-height: 63px;
  vertical-align: baseline;
}

.ceo-text {
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background: linear-gradient(360deg, #8DDAD5 0%, #00CDAC 100%);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -o-background-clip: text;
  background-clip: text;
}

.marketing-text {
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background: linear-gradient(358.66deg, #00ACE1 35.4%, #0591D9 65.62%, #115CC8 99.44%),
              linear-gradient(0deg, #00ACE1, #00ACE1);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -o-background-clip: text;
  background-clip: text;
}

.card__mark {
  font-size: 11px;
  line-height: 24px;
  vertical-align: middle;
}

.card__description {
  font-size: 12px;
  line-height: 19px;
  vertical-align: middle;
  font-weight: 300;
  margin-top: 10px;
}

.card__table {
  margin-top: 24px;
}

.card__table td {
  vertical-align: middle;
  padding: 4px 0;
}

.card__table td img {
  margin-right: 16px;
  width: 16px;
}

.card__table td p {
  font-size: 14px;
  line-height: 22px;
  vertical-align: middle;
}

.card__table td span {
  font-weight: 600;
}

.pricing__select {
  width: 200px;
  margin: auto;
  margin-bottom: 32px;
}

.card__action {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: baseline;
  position: absolute;
  bottom: 24px;
  right: 0px;
}

.card__action .link {
  text-decoration: none;
  color: #00ace1;
  border-radius: 8px;
  border: 1px solid #00ace1;
  text-align: center;
  padding: 12px 64px;
}

@media (max-width: 1024px) {
  .pricing__container {
    padding: 24px;
    width: Calc(100% - 48px);
  }
}

@media (max-width: 768px) {
  .pricing__title {
    font-size: 32px;
  }
}