.contact-us {
  position: relative;
  background-color: #f7f8ff;
}

.contact-us__container {
  padding: 64px;
  width: Calc(100% - 128px);
  max-width: 1362.72px;
  margin: auto;
  text-align: center;
}

.contact-us__title {
  text-align: center;
  font-size: 48px;
  line-height: 76px;
  vertical-align: middle;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background: linear-gradient(358.66deg, #00ACE1 35.4%, #0591D9 65.62%, #115CC8 99.44%),
              linear-gradient(0deg, #00ACE1, #00ACE1);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -o-background-clip: text;
  background-clip: text;
  margin-bottom: 24px;
}

.contact-us__description {
  font-size: 24px;
  line-height: 32px;
  vertical-align: middle;
  text-align: center;
  margin-bottom: 32px;
}

.contact-us .link {
  background: linear-gradient(360deg, #00ace1 0.03%, #0591d9 34.37%, #115cc8 100%), #00ace1;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  text-decoration: none;
  color: #ffffff;
  padding: 12px 64px;
  margin-right: 24px;
  width: 160px;
  text-align: center;
  margin: auto;
}

.button {
  height: 48px;
}

@media (max-width: 1024px) {
  .contact-us__container {
    padding: 24px;
    width: Calc(100% - 48px);
  }

  .contact-us__title {
    font-size: 32px;
    line-height: 48px;
  }

  .contact-us__description {
    font-size: 20px;
  }
}