.how-to {
  position: relative;
}

.how-to__title {
  font-size: 48px;
  line-height: 76px;
  text-align: left;
  vertical-align: middle;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background: linear-gradient(358.66deg, #00ACE1 35.4%, #0591D9 65.62%, #115CC8 99.44%),
              linear-gradient(0deg, #00ACE1, #00ACE1);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -o-background-clip: text;
  background-clip: text;
}

.how-to__container {
  padding: 64px;
  width: Calc(100% - 128px);
  max-width: 1362.72px;
  margin: auto;
}

.how-to__content {
  display: flex;
  justify-content: space-between;
  padding: 24px 0;
}

.how-to__video {
  width: 603px;
}

.responsive-video {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.responsive-video iframe,
.responsive-video object,
.responsive-video embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.upload-excel, .instant-pay {
  order: -1;
}

.how-to__table td {
  vertical-align: middle;
  padding: 12px 0;
}

.how-to__table tr:last-child td:last-child {
  padding: 32px 0;
}

.how-to__table td img {
  width: 20px;
  margin-right: 16px;
  vertical-align: middle;
}

.how-to__table td p {
  font-size: 20px;
  line-height: 32px;
  font-weight: 500;
  vertical-align: middle;
}

.premium-tag {
  -webkit-text-fill-color: #ffffff;
  color: #ffffff;
  font-size: 16px;
  line-height: 24px;
  vertical-align: middle;
  border-radius: 40px;
  padding: 4px 32px;
  background: linear-gradient(180deg, #fe8a80 0%, #ffb400 100%);
}

.register-link {
  background: linear-gradient(360deg, #00ace1 0.03%, #0591d9 34.37%, #115cc8 100%), #00ace1;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  text-decoration: none;
  color: #ffffff;
  padding: 8px 88px;
  margin-right: 24px;
}


.contact-link {
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background: linear-gradient(358.66deg, #00ACE1 35.4%, #0591D9 65.62%, #115CC8 99.44%),
              linear-gradient(0deg, #00ACE1, #00ACE1);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -o-background-clip: text;
  background-clip: text;
  border: 1px solid #00ace1;
  border-radius: 8px;
  text-decoration: none;
  color: #ffffff;
  padding: 8px 24px;
}

@media (max-width: 1024px) {
  .how-to__content {
    flex-direction: column;
    align-items: center;
  }

  .upload-excel, .instant-pay {
    order: 0;
  }

  .how-to__container {
    padding: 24px;
    width: Calc(100% - 48px);
  }

  .how-to__video {
    margin-bottom: 24px;
  }
}

@media (max-width: 768px) {
  .how-to__video {
    margin-bottom: 24px;
  }

  .how-to__title {
    font-size: 32px;
    line-height: 48px;
    text-align: center;
  }

  .how-to__table td p {
    font-size: 16px;
    line-height: 24px;
  }

  .register-link {
    display: none;
  }

  .how-to__table tr:last-child {
    text-align: center;
  }
}

@media (max-width: 540px) {
  .how-to__video {
    width: 275px;
    margin-bottom: 24px;
  }
}

@media (max-width: 420px) {
  .how-to__title {
    font-size: 20px;
    line-height: 32px;
  }

  .how-to__table td p {
    font-size: 14px;
    line-height: 24px;
  }

  .contact-link {
    font-size: 14px;
  }
}