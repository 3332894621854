.product-detail {
  background-color: #f7f8ff;
}

.product-detail__container {
  margin: auto;
  padding: 64px;
  width: Calc(100% - 128px);
  max-width: 1362.72px;
}

.product-detail__title {
  font-size: 48px;
  line-height: 76px;
  vertical-align: middle;
  margin-bottom: 40px;
  text-align: center;
}

.product-detail__content {
  display: flex;
  justify-content: space-between;
}

.product-detail__image .image {
  width: 100%;
  max-height: 467px;
}

.big-text {
  font-size: 64px;
  line-height: 102px;
  text-align: center;
  width: 64px;
  vertical-align: middle;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background: linear-gradient(358.66deg, #00ACE1 35.4%, #0591D9 65.62%, #115CC8 99.44%),
              linear-gradient(0deg, #00ACE1, #00ACE1);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -o-background-clip: text;
  background-clip: text;
}

.normal-text {
  font-size: 28px;
  line-height: 44px;
  vertical-align: middle;
  text-align: left;
}

.product-detail__table td {
  padding: 0 16px;
  vertical-align: middle;
}

@media (max-width: 1024px) {
  .product-detail__container {
    padding: 24px;
    width: Calc(100% - 48px);
  }
}

@media (max-width: 768px) {
  .product-detail__title {
    font-size: 36px;
    line-height: 50px;
  }

  .big-text {
    font-size: 48px;
    line-height: 72px;
    width: 26px;
  }

  .normal-text {
    font-size: 20px;
    line-height: 28px;
  }

  .product-detail__content {
    flex-direction: column;
    align-items: center;
  }

  .product-detail__image .image {
    width: 100%;
    max-height: 300px;
    margin-bottom: 24px;
  }
}