.user-stat {
  display: flex;
}

.user-stat__container {
  display: flex;
  justify-content: space-between;
  padding: 64px;
  width: Calc(100% - 128px);
  max-width: 1362.72px;
  margin: auto;
}

.stat-box {
  font-size: 1.6rem;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background: linear-gradient(358.66deg, #00ACE1 35.4%, #0591D9 65.62%, #115CC8 99.44%),
              linear-gradient(0deg, #00ACE1, #00ACE1);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -o-background-clip: text;
  background-clip: text;
  text-align: center;
  padding: 16px;
}

.box-title {
  font-size: 24px;
  line-height: 32px;
}

.box-sub {
  font-size: 16px;
  line-height: 24px;
}

@media (max-width: 1024px) {
  .user-stat__container {
    padding: 24px;
    width: Calc(100% - 48px);
  }
}

@media (max-width: 768px) {
  .user-stat__container {
    flex-direction: column;
  }
}
